export const reviews = [
	{
		Icon: (
			<svg
				width="40"
				height="41"
				viewBox="0 0 40 41"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.5 17.6088V10.5H32.5V17.6088C32.5 22.9002 32.5 25.5458 30.8728 27.1895C29.2457 28.8333 26.6267 28.8333 21.3888 28.8333H18.6112C13.3733 28.8333 10.7544 28.8333 9.12718 27.1895C7.5 25.5458 7.5 22.9002 7.5 17.6088Z"
					stroke="#141B34"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M7.5 10.5L8.70192 7.9359C9.63408 5.9473 10.1002 4.953 11.0449 4.39316C11.9896 3.83333 13.2014 3.83333 15.625 3.83333H24.375C26.7987 3.83333 28.0103 3.83333 28.9552 4.39316C29.8998 4.953 30.366 5.9473 31.298 7.9359L32.5 10.5"
					stroke="#141B34"
					strokeWidth="1.5"
					strokeLinecap="round"
				/>
				<path
					d="M17.5 15.5H22.5"
					stroke="#141B34"
					strokeWidth="1.5"
					strokeLinecap="round"
				/>
				<path
					d="M20 33V37.1667M20 33H11.6667M20 33H28.3333M11.6667 33H7.50001C5.19883 33 3.33334 34.8655 3.33334 37.1667M11.6667 33V37.1667M28.3333 33H32.5C34.8012 33 36.6667 34.8655 36.6667 37.1667M28.3333 33V37.1667"
					stroke="#141B34"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		count: 1200,
		label: "premium products",
	},
	{
		Icon: (
			<svg
				width="40"
				height="41"
				viewBox="0 0 40 41"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M28.3333 18.5075C28.3333 17.9313 28.3333 17.6433 28.42 17.3867C28.672 16.6407 29.3363 16.3513 30.0018 16.0481C30.75 15.7074 31.124 15.537 31.4947 15.507C31.9155 15.473 32.337 15.5636 32.6967 15.7655C33.1735 16.0331 33.506 16.5416 33.8465 16.955C35.4188 18.8647 36.2048 19.8197 36.4925 20.8727C36.7247 21.7223 36.7247 22.611 36.4925 23.4607C36.073 24.9965 34.7475 26.284 33.7663 27.4757C33.2645 28.0852 33.0135 28.39 32.6967 28.5678C32.337 28.7697 31.9155 28.8603 31.4947 28.8263C31.124 28.7963 30.75 28.626 30.0018 28.2852C29.3363 27.982 28.672 27.6927 28.42 26.9467C28.3333 26.69 28.3333 26.402 28.3333 25.8258V18.5075Z"
					stroke="#141B34"
					strokeWidth="1.5"
				/>
				<path
					d="M11.6667 18.5077C11.6667 17.7823 11.6463 17.1304 11.0599 16.6203C10.8466 16.4348 10.5638 16.306 9.99819 16.0484C9.25002 15.7076 8.87594 15.5373 8.50527 15.5073C7.39319 15.4173 6.79487 16.1764 6.15355 16.9553C4.58125 18.865 3.7951 19.8198 3.50744 20.8728C3.2753 21.7227 3.2753 22.6113 3.50744 23.461C3.927 24.9968 5.25254 26.2842 6.23369 27.476C6.85215 28.2272 7.44295 28.9125 8.50527 28.8267C8.87594 28.7967 9.25002 28.6262 9.99819 28.2855C10.5638 28.0278 10.8466 27.899 11.0599 27.7135C11.6463 27.2035 11.6667 26.5517 11.6667 25.8262V18.5077Z"
					stroke="#141B34"
					strokeWidth="1.5"
				/>
				<path
					d="M8.33334 15.5C8.33334 9.97715 13.5567 5.5 20 5.5C26.4433 5.5 31.6667 9.97715 31.6667 15.5"
					stroke="#141B34"
					strokeWidth="1.5"
					strokeLinecap="square"
					strokeLinejoin="round"
				/>
				<path
					d="M31.6667 28.8333V30.1667C31.6667 33.1122 28.6818 35.5 25 35.5H21.6667"
					stroke="#141B34"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		count: 1200,
		label: "premium products",
	},
	{
		Icon: (
			<svg
				width="40"
				height="41"
				viewBox="0 0 40 41"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M20 28.8333C17.2107 28.8333 14.7828 30.9417 13.5294 34.052C12.9308 35.5375 13.7898 37.1667 14.9313 37.1667H25.0687C26.2102 37.1667 27.0692 35.5375 26.4705 34.052C25.2172 30.9417 22.7893 28.8333 20 28.8333Z"
					stroke="#141B34"
					strokeWidth="1.5"
					strokeLinecap="round"
				/>
				<path
					d="M30.8333 8.83333H32.837C34.8385 8.83333 35.8392 8.83333 36.3613 9.46226C36.8833 10.0912 36.6663 11.0352 36.2322 12.9232L35.5808 15.7551C34.6015 20.0143 31.0182 23.1813 26.6667 23.8333"
					stroke="#141B34"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.16666 8.83333H7.16298C5.16153 8.83333 4.16081 8.83333 3.63873 9.46226C3.11665 10.0912 3.33373 11.0352 3.76791 12.9232L4.41913 15.7551C5.39856 20.0143 8.98186 23.1813 13.3333 23.8333"
					stroke="#141B34"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M20 28.8333C25.0347 28.8333 29.275 21.0632 30.5495 10.4848C30.902 7.55929 31.0783 6.09654 30.1447 4.96493C29.2112 3.83333 27.7038 3.83333 24.689 3.83333H15.311C12.2963 3.83333 10.7889 3.83333 9.85529 4.96493C8.92169 6.09654 9.09792 7.55929 9.45042 10.4848C10.725 21.0632 14.9654 28.8333 20 28.8333Z"
					stroke="#141B34"
					strokeWidth="1.5"
					strokeLinecap="round"
				/>
			</svg>
		),
		count: 1200,
		label: "award winning",
	},
];

export const services = [
	{
		Icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6.88333 21.6333V12.1914C6.88333 11.141 6.88333 10.6158 7.12383 10.1753C7.36433 9.73481 7.80611 9.4508 8.68969 8.8828L10.7365 7.56698C11.253 7.23498 11.5112 7.06897 11.8 7.06897C12.0888 7.06897 12.347 7.23498 12.8635 7.56698L14.9103 8.8828C15.7939 9.4508 16.2356 9.73481 16.4761 10.1753C16.7167 10.6158 16.7167 11.141 16.7167 12.1914V21.6333"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M11.8 12.7833H11.8088"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M20.65 21.6333V15.8929C20.65 13.6395 19.4118 13.4571 16.7167 12.7833"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M2.95001 21.6333V15.8929C2.95001 13.6395 4.18819 13.4571 6.88335 12.7833"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M1.96667 21.6334H21.6333"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M11.8 21.6333V17.7"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
				/>
				<path
					d="M11.8 6.88335V4.89919M11.8 4.89919V2.92086C11.8 2.45456 11.8 2.22141 11.9439 2.07655C12.396 1.62191 14.2583 2.69732 14.9666 3.13343C15.5647 3.50171 15.7333 4.23733 15.7333 4.89919H11.8Z"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		title: "education",
		service:
			"Generate WAEC and NECO Result Checker PINs and Tokens at unbeatable prices",
	},
	{
		Icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8.85 5.90002L9.24315 8.07725C9.30817 8.50408 9.26855 8.93727 9.12661 9.35135L4.91667 21.6334M14.75 5.90002L14.3569 8.07725C14.2919 8.50408 14.3315 8.93727 14.4734 9.35135L18.6833 21.6334"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M2.95 10.8167L3.26835 9.7555C3.35412 9.46963 3.39699 9.32671 3.4938 9.20711C3.59062 9.08753 3.7336 9.00087 4.01956 8.82756L8.33279 6.21348C8.58923 6.05807 8.71745 5.98036 8.86567 5.94019C9.0139 5.90002 9.17244 5.90002 9.48953 5.90002H14.1104C14.4276 5.90002 14.5861 5.90002 14.7344 5.94019C14.8826 5.98036 15.0108 6.05808 15.2672 6.21348L19.5804 8.82756C19.8664 9.00087 20.0094 9.08753 20.1062 9.20711C20.203 9.3267 20.2459 9.46964 20.3316 9.7555L20.65 10.8167"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M2.95 21.6334H20.65"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3.93333 8.84998H19.175"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.34167 9.83331L15.7333 14.2583L4.91667 21.6333"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.2583 9.83331L7.86667 14.2583L18.6833 21.6333"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8.85 5.90001L10.2267 3.69734C10.9477 2.54357 11.3083 1.96667 11.8 1.96667C12.2917 1.96667 12.6523 2.54357 13.3733 3.69734L14.75 5.90001"
					stroke="white"
					strokeWidth="1.5"
				/>
			</svg>
		),
		title: "electricity",
		service:
			"We offer fast recharge of prepaid meter with instant token delivery. E.g PHEDC, EKEDC IKEDC, AEDC e.t.c.",
	},
	{
		Icon: (
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M12.398 11.5167V20.3667"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
				/>
				<path
					d="M14.3647 9.55004C14.3647 10.6362 13.4842 11.5167 12.398 11.5167C11.3118 11.5167 10.4314 10.6362 10.4314 9.55004C10.4314 8.46388 11.3118 7.58337 12.398 7.58337C13.4842 7.58337 14.3647 8.46388 14.3647 9.55004Z"
					stroke="white"
					strokeWidth="1.5"
				/>
				<path
					d="M17.2742 6.60004C17.923 7.45515 18.298 8.46641 18.298 9.55004C18.298 10.6337 17.923 11.6449 17.2742 12.5M7.52183 6.60004C6.87309 7.45515 6.49802 8.46641 6.49802 9.55004C6.49802 10.6337 6.87309 11.6449 7.52183 12.5"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M20.5753 4.63336C21.6213 6.03961 22.2314 7.7307 22.2314 9.55003C22.2314 11.3694 21.6213 13.0604 20.5753 14.4667M4.22072 4.63336C3.17472 6.03961 2.5647 7.7307 2.5647 9.55003C2.5647 11.3694 3.17472 13.0604 4.22072 14.4667"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		title: "airtime service",
		service: `Making an online recharge has become very easy, fast and safe on ${process.env.REACT_APP_AGENT_NAME}... We offer instant recharge of Airtime with discounts for all networks.`,
	},
	{
		Icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M1.96667 13.7667C1.96667 10.0583 1.96667 8.20412 3.11872 7.05207C4.27077 5.90002 6.12496 5.90002 9.83334 5.90002H13.7667C17.475 5.90002 19.3293 5.90002 20.4813 7.05207C21.6333 8.20412 21.6333 10.0583 21.6333 13.7667C21.6333 17.475 21.6333 19.3293 20.4813 20.4813C19.3293 21.6334 17.475 21.6334 13.7667 21.6334H9.83334C6.12496 21.6334 4.27077 21.6334 3.11872 20.4813C1.96667 19.3293 1.96667 17.475 1.96667 13.7667Z"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
				/>
				<path
					d="M8.85001 2.95001L11.8 5.90001L15.7333 1.96667"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		title: "TV subsciption",
		service:
			"Instantly activate cable TV subscriptions such as DStv, GOtv and Startimes with favourable discounts.",
	},
	{
		Icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.23139 17.7C5.14366 17.7 4.09979 17.7 3.3566 17.2543C2.87087 16.9631 2.47955 16.5466 2.227 16.0521C1.84059 15.2954 1.94446 14.295 2.1522 12.2941C2.32561 10.6238 2.41232 9.78861 2.83873 9.17527C3.11867 8.77257 3.49731 8.44252 3.94117 8.21428C4.61722 7.86664 5.48861 7.86664 7.23139 7.86664H16.3686C18.1114 7.86664 18.9828 7.86664 19.6588 8.21428C20.1027 8.44252 20.4814 8.77257 20.7613 9.17527C21.1877 9.78861 21.2744 10.6238 21.4478 12.2941C21.6556 14.295 21.7594 15.2954 21.3729 16.0521C21.1204 16.5466 20.7292 16.9631 20.2434 17.2543C19.5002 17.7 18.4564 17.7 16.3686 17.7"
					stroke="white"
					strokeWidth="1.5"
				/>
				<path
					d="M16.7167 7.86667V5.90001C16.7167 4.04582 16.7167 3.11872 16.1406 2.5427C15.5646 1.96667 14.6375 1.96667 12.7833 1.96667H10.8167C8.96247 1.96667 8.03538 1.96667 7.45936 2.5427C6.88333 3.11872 6.88333 4.04582 6.88333 5.90001V7.86667"
					stroke="white"
					strokeWidth="1.5"
					strokeLinejoin="round"
				/>
				<path
					d="M13.7556 15.7333H9.84444C9.17059 15.7333 8.83365 15.7333 8.54697 15.8404C8.16472 15.9831 7.83742 16.2604 7.6171 16.6281C7.45185 16.9039 7.37013 17.2586 7.20669 17.9679C6.95131 19.0762 6.82363 19.6303 6.91046 20.0747C7.02626 20.6671 7.38716 21.1686 7.88881 21.4341C8.26504 21.6333 8.79151 21.6333 9.84444 21.6333H13.7556C14.8085 21.6333 15.335 21.6333 15.7112 21.4341C16.2128 21.1686 16.5738 20.6671 16.6895 20.0747C16.7764 19.6303 16.6487 19.0762 16.3933 17.9679C16.2299 17.2586 16.1481 16.9039 15.9829 16.6281C15.7625 16.2604 15.4353 15.9831 15.0531 15.8404C14.7663 15.7333 14.4294 15.7333 13.7556 15.7333Z"
					stroke="white"
					strokeWidth="1.5"
					strokeLinejoin="round"
				/>
				<path
					d="M17.7 11.8H17.7089"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		title: "recharge card printing",
		service:
			"Print recharge cards of all networks in your desired business name",
	},
	{
		Icon: (
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M16.6938 19.175C19.0784 17.5895 20.65 14.8783 20.65 11.8C20.65 6.91229 16.6878 2.95001 11.8 2.95001C11.1241 2.95001 10.4658 3.02579 9.83335 3.16932M16.6938 19.175V15.7333M16.6938 19.175H20.1583M6.88335 4.4403C4.51158 6.02791 2.95001 8.7316 2.95001 11.8C2.95001 16.6878 6.91229 20.65 11.8 20.65C12.476 20.65 13.1342 20.5742 13.7667 20.4307M6.88335 4.4403V7.86668M6.88335 4.4403H3.44168"
					stroke="white"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		title: "airtime 2 cash",
		service:
			"Convert excess MTN, GLO, AIRTEL and 9MOBILE Airtime to Cash at Superb rates",
	},
];
