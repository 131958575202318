export const item = delay => {
	return {
		hide: {
			opacity: 0,
			y: 100,
		},
		show: {
			opacity: 1,
			y: 0,
			transition: {
				delay: 0.5 + delay / 10,
			},
		},
	};
};
