import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import Button from "../button/button";
import { useState } from "react";
import { Link } from "react-router-dom";

const links = [
	{ id: 1, name: "About", url: "#about" },
	{
		id: 1,
		name: "Contact",
		url: process.env.REACT_APP_AGENT_WHATSAPP || "",
	},
	{ id: 1, name: "Services", url: "#services" },
];

const Navigation = () => {
	const [showNav, setShowNav] = useState(false);
	const [nav, setNav] = useState(false);

	window.onscroll = () => {
		window.pageYOffset > 200
			? // console.log(pageYOffset)
			  setNav(true)
			: setNav(false);
	};

	const handleNav = () => {
		setShowNav(!showNav);
	};

	const closeNav = () => {
		setShowNav(false);
	};
	console.log({ nav });
	return (
		<nav className="container mx-auto px-4 flex justify-between items-center absolute inset-x-0 x-50 py-3">
			<span className="text-main uppercase font-medium text-xl">
				<Link
					to="/"
					className="text-decoration-none text-dark d-flex align-items-center">
					<img
						src={process.env.REACT_APP_IMAGE_URL}
						alt={`logo ${process.env.REACT_APP_NAME}`}
						className="logo logo-height logo2 me-1 rounded10"
					/>
				</Link>
			</span>
			<div className="md:flex gap-8 hidden">
				{links.map((link, id) => (
					<span key={id}>
						<a href={link.url} className="text-main uppercase">
							{link.name}
						</a>
					</span>
				))}
			</div>
			<div className="">
				<div className="hidden md:block">
					<Button />
				</div>
				<div className="md:hidden p-2 pointer-cursor" onClick={handleNav}>
					<MenuIcon />
				</div>
			</div>

			{/* mobile */}
			{showNav && (
				<div
					data-aos="fade-right"
					data-aos-offset="300"
					// data-aos-easing="ease-in-sine"
					data-aos-easing="ease-out-cubic"
					data-aos-duration="800"
					className={`fixed inset-y-0 left-0 z-50 w-5/6 lg:hidden flex ${
						!showNav && "hidden"
					}`}>
					<div
						className="fixed -z-[1] opacity-30 inset-0 bg-dark"
						onClick={closeNav}></div>
					<div className="w-full px-12 py-10 bg-tetiary h-screen">
						<div className="flex justify-between items-center mb-8">
							<span className="text-main uppercase font-medium text-xl">
								<Link
									to="/"
									className="text-decoration-none text-dark d-flex align-items-center">
									<img
										src={process.env.REACT_APP_IMAGE_URL}
										alt={`logo ${process.env.REACT_APP_NAME}`}
										className="logo logo-height logo2 me-1 rounded10"
									/>
								</Link>
							</span>
							<button className="text-white p-4" onClick={handleNav}>
								<CloseIcon fontSize="large" />
							</button>
						</div>
						<ul className="mt-12">
							{links.map((link, id) => (
								<li className={`mb-10 ${!showNav && "hidden"}`} key={id}>
									<a
										href={link.link}
										className="text-white text-2xl font-medium text-decoration-none"
										onClick={closeNav}>
										{link.name}
									</a>
								</li>
							))}
						</ul>
						<div className="md:hidden">
							<Button />
						</div>
					</div>
				</div>
			)}
		</nav>
	);
};

export default Navigation;
