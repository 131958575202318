import { NavLink } from "react-router-dom";
// import Logo from "../../Assets/dean-logo.png";
import { classnames } from "../../Utils/classnames";

export const FooterSection = ({ routes }) => {
	return (
		<footer className="bg-[#3E3F94] p-10">
			<div className="max-w-7xl mx-auto">
				<div className="max-w-full grid grid-cols-2 sm:grid-cols-2">
					<div className="space-y-3">
						<div className="flex flex-1 items-center justify-center sm:justify-start">
							<img
								src={process.env.REACT_APP_IMAGE_URL}
								alt="logo"
								className="h-28"
							/>
						</div>
						<p className="text-white font-normal font-roboto text-lg">
							Own a VTU website like ours and carry out your business swiftly,
							you can start reselling by having access to all our services such
							as: DATA BUNDLES, AIRTIME VTU, BULK SMS, AIRTIME TO CASH, CABLE TV
							SUBSCRIPTIONS, ELECTRICITY BILLS PAYMENT, DATA CARDS, RECHARGE
							CARDS PRINTING etc at affordable prices. Interested? WHATSAPP{" "}
							{process.env.REACT_APP_AGENT_WHATSAPP
								? process.env.REACT_APP_AGENT_WHATSAPP?.replace(
										"https://wa.me/234",
										"0"
								  )
								: process.env.REACT_APP_AGENT_TELEPHONE}
						</p>
					</div>

					<div className="flex space-x-8 items-center justify-end">
						{routes.map(item => (
							<NavLink
								key={item.title}
								to={item.to}
								className={() =>
									classnames(
										"text-xl font-normal font-roboto flex-shrink-0 capitalize text-white"
									)
								}
								aria-current={item.current ? "page" : undefined}>
								{item.title}
							</NavLink>
						))}
					</div>
				</div>

				<hr className="mt-10" />
				<div className="flex items-center justify-end mt-2">
					<small className="text-white font-roboto font-normal text-sm">
						Copyright @{process.env.REACT_APP_AGENT_NAME}{" "}
						{new Date().getFullYear()}. All Rights Reserved.
					</small>
				</div>
			</div>
		</footer>
	);
};
