import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import WomanImg from "../../../Assets/woman-pressing-phone.jfif";
import { Link } from "react-router-dom";
import { item } from "../../../Utils/framer-motion/motion";

export const BenefitSection = () => {
	const control = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			control.start("show");
		} else {
			control.start("hide");
		}
	}, [control, inView]);

	return (
		<section className="pb-10 relative" id="benefits">
			<div className="max-w-7xl mx-auto xl:h-screen">
				<div className="grid grid-cols-1 lg:grid-cols-2 xl:h-full xl:place-content-center gap-5 px-4 xl:px-0">
					<div className="rounded-xl overflow-hidden lg:h-[20rem] border">
						<img
							src={WomanImg}
							alt=""
							className="w-full h-full object-cover object-top"
						/>
					</div>

					<div className="h-full flex flex-col justify-center space-y-6 max-w-xl">
						<h1 className="font-roboto-slab font-medium text-xl sm:text-2xl lg:text-4xl text-[#2F303F]">
							Benefits We Offer to You
						</h1>

						<p className="font-roboto font-normal text-lg sm:text-xl text-[#2F303F]">
							Choosing {process.env.REACT_APP_AGENT_NAME} comes with a multitude
							of advantages designed to enhance your experience and simplify
							your life. Discover the benefits of partnering with us:
						</p>

						<Link
							to="/login"
							className="text-white inline-block w-fit rounded font-roboto font-medium text-base capitalize px-6 py-2.5 bg-[#3E3F94] hover:bg-[#3E3F94]/70 transition-all focus:ring-1 focus:ring-offset-2 focus:ring-[#3E3F94]/70 active:bg-[#3E3F94]">
							get started
						</Link>
					</div>

					<motion.div
						animate={control}
						ref={ref}
						variants={{ ...item(0) }}
						initial="hide"
						className="w-full text-[#2F303F] space-y-3">
						<div className="flex items-center space-x-2">
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M21.25 4.17228C19.4114 3.10871 17.2768 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9035 8.09644 27.5 15 27.5C21.9035 27.5 27.5 21.9035 27.5 15C27.5 14.1439 27.4139 13.3078 27.25 12.5"
									stroke="#141B34"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M10 15.625C10 15.625 11.875 15.625 14.375 20C14.375 20 21.3235 8.54166 27.5 6.25"
									stroke="#141B34"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<h3 className="text-xl font-roboto font-medium capitalize">
								convenience
							</h3>
						</div>
						<p className="text-lg font-normal font-roboto">
							Manage all your digital essentials in one place. Our platform
							provides a seamless and efficient way to handle your airtime,
							data, TV, and electricity needs, saving you time and effort.
						</p>
					</motion.div>

					<motion.div
						animate={control}
						ref={ref}
						variants={{ ...item(1) }}
						initial="hide"
						className="w-full text-[#2F303F] space-y-3">
						<div className="flex items-center space-x-2">
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M21.25 4.17228C19.4114 3.10871 17.2768 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9035 8.09644 27.5 15 27.5C21.9035 27.5 27.5 21.9035 27.5 15C27.5 14.1439 27.4139 13.3078 27.25 12.5"
									stroke="#141B34"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M10 15.625C10 15.625 11.875 15.625 14.375 20C14.375 20 21.3235 8.54166 27.5 6.25"
									stroke="#141B34"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<h3 className="text-xl font-roboto font-medium capitalize">
								speed
							</h3>
						</div>
						<p className="text-lg font-normal font-roboto">
							Manage all your digital essentials in one place. Our platform
							provides a seamless and efficient way to handle your airtime,
							data, TV, and electricity needs, saving you time and effort.
						</p>
					</motion.div>

					<motion.div
						animate={control}
						ref={ref}
						variants={{ ...item(2) }}
						initial="hide"
						className="w-full text-[#2F303F] space-y-3">
						<div className="flex items-center space-x-2">
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M21.25 4.17228C19.4114 3.10871 17.2768 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9035 8.09644 27.5 15 27.5C21.9035 27.5 27.5 21.9035 27.5 15C27.5 14.1439 27.4139 13.3078 27.25 12.5"
									stroke="#141B34"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M10 15.625C10 15.625 11.875 15.625 14.375 20C14.375 20 21.3235 8.54166 27.5 6.25"
									stroke="#141B34"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<h3 className="text-xl font-roboto font-medium capitalize">
								affordability
							</h3>
						</div>
						<p className="text-lg font-normal font-roboto">
							Benefit from competitive pricing and affordable plans. We offer a
							range of options tailored to fit your budget, ensuring you get the
							best value for your money.
						</p>
					</motion.div>

					<motion.div
						animate={control}
						ref={ref}
						variants={{ ...item(3) }}
						initial="hide"
						className="w-full text-[#2F303F] space-y-3">
						<div className="flex items-center space-x-2">
							<svg
								width="30"
								height="30"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M21.25 4.17228C19.4114 3.10871 17.2768 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9035 8.09644 27.5 15 27.5C21.9035 27.5 27.5 21.9035 27.5 15C27.5 14.1439 27.4139 13.3078 27.25 12.5"
									stroke="#141B34"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M10 15.625C10 15.625 11.875 15.625 14.375 20C14.375 20 21.3235 8.54166 27.5 6.25"
									stroke="#141B34"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<h3 className="text-xl font-roboto font-medium capitalize">
								customer support
							</h3>
						</div>
						<p className="text-lg font-normal font-roboto">
							Receive exceptional customer support whenever you need it. Our
							dedicated team is here to assist you with any questions or issues,
							ensuring a smooth and satisfying experience.
						</p>
					</motion.div>
				</div>
			</div>
		</section>
	);
};
