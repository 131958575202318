import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { reviews } from "../../Data/reviews";
import { item } from "../../Utils/framer-motion/motion";

export const ReviewSection = () => {
	const control = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			control.start("show");
		} else {
			control.start("hide");
		}
	}, [control, inView]);

	const containerVariants = {
		final: {
			transition: {
				staggerChildren: 0.54,
			},
		},
	};

	return (
		<section className="relative shadow overflow-hidden">
			<motion.div
				variants={containerVariants}
				animate="final"
				className="max-w-7xl mx-auto w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 place-content-center place-items-center py-10">
				{reviews.map(({ label, Icon, count }, i) => (
					<motion.div
						variants={{ ...item(i) }}
						key={label}
						animate={control}
						initial="hide"
						ref={ref}
						className="flex items-center justify-start space-x-8 text-[#2F303F]">
						{Icon}
						<div className="space-y-2.5">
							<h3 className="font-roboto font-meduim text-lg sm:text-xl capitalize">
								{label}
							</h3>
							<p className="font-roboto font-normal text-lg">{count}+</p>
						</div>
					</motion.div>
				))}
			</motion.div>
		</section>
	);
};
