import React from "react";

const HomeSixteenCard = ({ icon, title, description }) => {
  return (
    <div className="cursor-pointer transition-shadow border-2 rounded-2xl hover:shadow-lg flex flex-col gap-5 items-center text-center pt-6 px-7">
      <div className="mt-4">{icon}</div>
      <h3 className=" text-gray-900 font-semibold capitalize text-2xl">{title}</h3>
      <p className="text-base sm:text-lg lg:text-xl">{description}</p>
    </div>
  );
};

export default HomeSixteenCard;
