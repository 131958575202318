import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Logo from "../../Assets/HomeSixteenLogo.png";
import AppLogo from "../app-logo";

const HomeSixteenNavigation = () => {
	const [open, setOpen] = useState(false),
		navigate = useNavigate();

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<nav className="z-10 bg-transparent h-28 absolute left-0 right-0">
			<div className="h-full flex items-center justify-between max-w-7xl mx-auto w-full px-4 xl:px-0">
				<div className="flex items-center space-x-14 h-full">
					<div className="flex w-full lg:w-fit items-center justify-center lg:justify-start -mt-5">
						<AppLogo type="dark" height="50px" />
					</div>
					<div className="hidden lg:flex items-center gap-4 h-full">
						<Link
							to="#"
							className="no-underline capitalize text-base sm:text-lg lg:text-xl text-white font-medium">
							product
						</Link>
						<Link
							to="#"
							className="no-underline capitalize text-base sm:text-lg lg:text-xl text-white font-medium">
							about us
						</Link>
						<Link
							to="#"
							className="no-underline capitalize text-base sm:text-lg lg:text-xl text-white font-medium">
							contact us
						</Link>
					</div>
				</div>

				<div className="z-10 flex items-center lg:block gap-3 lg:gap-0">
					<button
						className="px-4 py-2 bg-white text-gray-800 rounded capitalize font-normal"
						type="button"
						onClick={() => navigate("/login")}>
						get started
					</button>
					<div className="z-10 lg:hidden">
						<button onClick={handleOpen} type="button">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="lg:hidden w-8 h-8 fill-white"
								viewBox="0 0 448 512">
								<path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z" />
							</svg>
						</button>
					</div>
				</div>
			</div>

			<div
				className={`bg-homesixteenhero fixed top-0 h-screen lg:hidden w-full sm:w-[25rem] z-20 border-r border-white/20 transition ${
					open ? "translate-x-0" : "-translate-x-full"
				}`}>
				<button className="absolute right-3 top-2" onClick={handleClose}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="w-8 h-8 fill-white"
						viewBox="0 0 320 512">
						<path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
					</svg>
				</button>
				<div className="flex flex-col gap-4 min-h-full w-full p-4 mt-5">
					<Link
						to="#"
						className="no-underline capitalize text-base sm:text-lg lg:text-xl text-white font-medium">
						product
					</Link>
					<Link
						to="#"
						className="no-underline capitalize text-base sm:text-lg lg:text-xl text-white font-medium">
						about us
					</Link>
					<Link
						to="#"
						className="no-underline capitalize text-base sm:text-lg lg:text-xl text-white font-medium">
						contact us
					</Link>
				</div>
			</div>
		</nav>
	);
};

export default HomeSixteenNavigation;
