import Button from "../../Components/home-seventeen/button/button";
import Card from "../../Components/home-seventeen/card/card";
import Benefits from "../../Components/home-seventeen/benefits/benefits";
import benefitsBg from "../../Assets/img/benefits-bg.png";
import footerBg from "../../Assets/img/footer-bg.jpeg";
import Google from "../../Assets/google.svg";
import Ios from "../../Assets/ios.svg";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Navigation from "../../Components/home-seventeen/navigation/navigation";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import { SuportChannels } from "../home";

const HomeSeventeen = () => {
	return (
		<div className="">
			<header className="relative">
				<div className="absolute bg-main inset-0 opacity-20"></div>
				<Navigation />

				<div className="container mx-auto px-4 pt-40 md:pt-20">
					<div className="grid md:grid-cols-2 gap-12">
						<div
							data-aos="zoom-in"
							data-aos-duration="3000"
							className="self-center max-w-md">
							<p className="font-bold text-4xl lg:text-5xl uppercase B612">
								Never run out of data. Stay connected with{" "}
								{process.env.REACT_APP_AGENT_NAME}!
							</p>
							<p className="mt-8">
								A technology platform that offers you best quality and
								affordable telecom services right from the comfort of your home
								or office.
							</p>
							<div className="mt-8 flex items-center">
								<Button />
								<div className="py-2 ps-5">
									<div className="mt-4 flex gap-5">
										<a
											href={process.env.REACT_APP_GOOGLE_PLAY || "#"}
											target={process.env.REACT_APP_GOOGLE_PLAY ? "_blank" : ""}
											rel="noopener noreferrer">
											<img
												src={Google}
												alt=""
												className="rounded-full h-10 lg:h-full"
											/>
										</a>
										<a
											className="hidden"
											href={process.env.REACT_APP_IOS_PLAY || "#"}
											target={process.env.REACT_APP_IOS_PLAY ? "_blank" : ""}
											rel="noopener noreferrer">
											<img
												src={Ios}
												alt=""
												className="rounded-full h-10 justify-center lg:justify-center gap-5 lg:h-full"
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div
							data-aos="fade-left"
							data-aos-easing="linear"
							data-aos-duration="1500">
							<img
								src={require("../../Assets/img/hero.png")}
								alt="Hero of a girl in yelloshirt pressing her phone"
							/>
						</div>
					</div>
				</div>
			</header>
			<section className="bg-main py-4">
				<div className="flex items-center justify-center gap-20">
					<div className="flex items-center gap-4 text-white">
						<img
							src={require("../../Assets/img/icon-1.png")}
							alt="icon1"
							className="h-5"
						/>
						<span>
							<p className="text-md mb-1 font-medium">1200+</p>
							<p className="text-xs">Premium Products</p>
						</span>
					</div>
					<div className="flex items-center gap-4 text-white">
						<img
							src={require("../../Assets/img/icon-2.png")}
							alt="icon2"
							className="h-5"
						/>
						<span>
							<p className="text-md mb-1 font-medium">4500+</p>
							<p className="text-xs">Happy Customer</p>
						</span>
					</div>
					<div className="flex items-center gap-4 text-white">
						<img
							src={require("../../Assets/img/icon3.png")}
							alt="icon"
							className="h-5"
						/>
						<span>
							<p className="text-md mb-1 font-medium">240+</p>
							<p className="text-xs">Award Winning</p>
						</span>
					</div>
				</div>
			</section>
			<section id="services" className="bg-secondary p-10">
				<p className="text-2xl font-bold text-center">
					Our services Especially for you
				</p>
				<div className="container mx-auto">
					<div className="grid md:grid-cols-3 gap-x-8 gap-y-10 mt-10">
						<Card
							img="ico-1.png"
							title="Data Services"
							text="Enjoy internet browsing always with our attractive data rates. Start your data reselling business with us today and enjoy profitable discounts for all networks."
						/>
						<Card
							img="ico-2.png"
							title="Airtime Services"
							text={`Making an online recharge has become very easy, fast and safe on ${process.env.REACT_APP_AGENT_NAME}... We offer instant recharge of Airtime with discounts for all networks.`}
						/>
						<Card
							img="ico-3.png"
							title="Electricity"
							text="We offer fast recharge of prepaid meter with instant token delivery. E.g PHEDC, EKEDC IKEDC, AEDC e.t.c."
						/>
						<Card
							img="ico-4.png"
							title="Education"
							text="Generate WAEC and NECO Result Checker PINs and Tokens at unbeatable prices"
						/>
						<Card
							img="ico-5.png"
							title="Tv Subscription"
							text="Instantly activate cable TV subscriptions such as DStv, GOtv and Startimes with favourable discounts."
						/>
						<Card
							img="ico-6.png"
							title="Recharge Card Printing"
							text="Print recharge cards of all networks in your desired business name"
						/>
						<Card
							img="ico-6.png"
							title="Airtime 2 Cash"
							text="Convert excess MTN, GLO, AIRTEL and 9MOBILE Airtime to Cash at Superb rates."
						/>
					</div>
				</div>
			</section>
			<section
				id="about"
				className="px-8 py-20"
				style={{ background: `url(${benefitsBg})`, backgroundSize: "cover" }}>
				<div className="container mx-auto">
					<div className="grid md:grid-cols-2 gap-10">
						<div
							data-aos="fade-right"
							data-aos-easing="linear"
							data-aos-duration="1500">
							<img
								src={require("../../Assets/img/benefits-hero.png")}
								className="h-[300px] md:h-[500px] mx-auto"
								alt="benfits"
							/>
						</div>
						<div className="text-white">
							<p
								data-aos="zoom-in-up"
								data-aos-duration="2000"
								className="font-bold text-4xl">
								Benefits we <br /> Offer to you
							</p>
							<p
								data-aos="zoom-in-up"
								data-aos-duration="2000"
								className="mt-8">
								Join our network of outstanding entrepreneurs partnering with
								{process.env.REACT_APP_AGENT_NAME}. Bring our 'easy-payments'
								experience closer to your home or office and earn a commission
								for every transaction you perform for yourself or customers.
							</p>
							<div className="py-8">
								<Benefits title="Recharge and Earn!" />
								<Benefits title="Buy and Earn!" />
								<Benefits title="Sell and Earn!" />
								<Benefits title="Refer and Earn!" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="bg-secondary py-12 px-4">
				<div data-aos="zoom-in-up" data-aos-duration="2000" className="">
					<p className="text-center font-medium text-lg">
						Got a question? We're here to help! Contact our customer service
						team and <br /> we'll get back to you as soon as possible.
					</p>
					<div className="flex items-center justify-center gap-12 mt-8 text-[#4285F4] text-xs">
						<div className="">
							<span className="d-flex text-center py-2 myCursor">
								<span className="pe-2">
									<FaPhone />
								</span>{" "}
								{process.env.REACT_APP_AGENT_TELEPHONE}
							</span>
							<span className="d-flex text-center py-2 myCursor">
								<span className="pe-2">
									<MdEmail />
								</span>{" "}
								{process.env.REACT_APP_AGENT_EMAIL}
							</span>
						</div>
					</div>
				</div>
			</section>
			<section className="py-10 bg-[#ECEBF9] text-center">
				<div className="container">
					{/* <p
						data-aos="fade-up"
						data-aos-duration="3000"
						className="text-4xl font-bold text-[#0C111F] m-0">
						Subscribe to Our Newsletter
					</p>
					<p data-aos="fade-up" data-aos-duration="3000" className="mt-8">
						small heading
					</p>
					<div
						data-aos="fade-up"
						data-aos-duration="3000"
						className="flex bg-white p-1 rounded-md max-w-sm mx-auto">
						<input
							type="text"
							className="bg-transparent h-12 px-4 w-full"
						/>
						<button className="h-12 w-40 rounded-md bg-[#0C03B6] text-light">
							Join Now
						</button>
					</div> */}
					<SuportChannels />
				</div>
			</section>
			<section className="bg-[#BAD7F2]">
				<div className="container mx-auto px-4">
					<div className="grid md:grid-cols-2 gap-10">
						<div className="self-center py-8">
							<p
								data-aos="zoom-in-up"
								data-aos-duration="2000"
								className="text-main text-3xl font-bold">
								Deal of the day
							</p>
							<p
								data-aos="zoom-in-up"
								data-aos-duration="2000"
								className="max-w-lg mt-4">
								Join our Reseller team where we expose you to the hidden wealth
								in telecommunication industry
							</p>
							{/* <div
                data-aos="zoom-in-up"
                data-aos-duration="2000"
                className="flex items-center gap-10 my-8"
              >
                <div className="bg-main text-white w-16 h-16 flex flex-col items-center justify-center">
                  <p className="font-bold text-lg">01</p>
                  <p className="text-sm font-light">Day</p>
                </div>
                <div className="bg-main text-white w-16 h-16 flex flex-col items-center justify-center">
                  <p className="font-bold text-lg">09</p>
                  <p className="text-sm font-light">Hour</p>
                </div>
                <div className="bg-main text-white w-16 h-16 flex flex-col items-center justify-center">
                  <p className="font-bold text-lg">09</p>
                  <p className="text-sm font-light">Minute</p>
                </div>
              </div> */}
							<div data-aos="zoom-in-up" data-aos-duration="2000">
								<a href={process.env.REACT_APP_AGENT_WHATSAPP}>
									<Button />
								</a>
							</div>
						</div>
						<div
							data-aos="fade-left"
							data-aos-easing="linear"
							data-aos-duration="1500">
							<img
								src={require("../../Assets/img/deal-hero.png")}
								className="h-[350px] mx-auto"
								alt="deals"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="my-20">
				<div
					data-aos="zoom-in-up"
					data-aos-duration="2000"
					className="max-w-lg mx-auto flex items-top gap-10">
					<div className="-mt-10">
						<FormatQuoteIcon
							style={{ fontSize: "80px" }}
							className="rotate-180 text-main"
						/>
					</div>
					<div>
						<p className="text-2xl font-light">
							I enjoy excellent customer service and affordable telecom services
							with swift delivery daily while using this platform. If you ever
							think of making more profits in your data reselling business,
							think of {process.env.REACT_APP_AGENT_NAME}.
						</p>
						<div className="flex gap-8 items-center mt-10">
							<img
								src={require("../../Assets/img/customer.png")}
								className="h-12"
								alt="customer"
							/>
							<div>
								<p className="text-lg">James Joseph</p>
								<p className="text-xs">Entrepreneur</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<footer
				style={{
					background: `url(${footerBg}) bottom`,
					backgroundSize: "cover",
				}}
				className="pt-32 pb-8 px-4">
				<div className="container mx-auto">
					<div className="grid grid-cols-2 md:grid-cols-8 gap-8">
						<div className="col-span-2 md:col-span-4 text-white">
							<Link to="/" className="">
								<img
									src={process.env.REACT_APP_IMAGE_URL}
									alt={`logo ${process.env.REACT_APP_AGENT_NAME}`}
									className="logo logo-height logo2 me-1 rounded10"
								/>
							</Link>
							<p className="max-w-lg my-4 text-sm leading-loose">
								<b>BE YOUR OWN BOSS!</b>
								<br />
								Own a VTU website like ours and carry out your business swiftly,
								you can start reselling by having access to all our services
								such as: DATA BUNDLES, AIRTIME VTU, BULK SMS, AIRTIME TO CASH,
								CABLE TV SUBSCRIPTIONS, ELECTRICITY BILLS PAYMENT, DATA CARDS,
								RECHARGE CARDS PRINTING etc at affordable prices. Interested?
								WHATSAPP {process.env.REACT_APP_AGENT_TELEPHONE}
							</p>
							<div>socials</div>
						</div>
						{/* <div className="">
              <ul className="text-white p-0">
                <li className="font-medium text-xl mb-6">About</li>
                <li className="text-xs font-normal mb-2">Services</li>
                <li className="text-xs font-normal mb-2">
                  Contact Us
                </li>
              </ul>
            </div>
            <div className="">
              <ul className="text-white p-0">
                <li className="font-medium text-xl mb-6">Support</li>
                <li className="text-xs font-normal mb-2">FAQs</li>
                <li className="text-xs font-normal mb-2">Blog</li>
              </ul>
            </div> */}
						<div className="w-full">
							<ul className="text-white p-0">
								<li className="font-medium text-xl mb-6">Contact</li>
								<li className="text-xs font-normal mb-4 flex items-center gap-2">
									<span>
										<FaPhone />
									</span>{" "}
									<span>{process.env.REACT_APP_AGENT_TELEPHONE}</span>
								</li>
								<li className="text-xs font-normal mb-4 flex items-center gap-2">
									<span>
										<MdEmail />
									</span>
									<span>{process.env.REACT_APP_AGENT_EMAIL}</span>
								</li>
								{/* <li className="text-xs font-normal mb-4 flex items-center gap-2">
									<span>
										<MdLocationOn />
									</span>
									<span>{process.env.REACT_APP_AGENT_ADDRESS}</span>
								</li> */}
							</ul>
						</div>
					</div>
					<div>
						<p className="text-white text-center text-xs mt-8">
							Copyright @ {process.env.REACT_APP_AGENT_NAME}{" "}
							{new Date().getFullYear()}. All Rights Reserved.
						</p>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default HomeSeventeen;
