import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Man from "../../../Assets/man-in-office.jfif";
import { item } from "../../../Utils/framer-motion/motion";

export const ContactSection = () => {
	const control = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			control.start("show");
		} else {
			control.start("hide");
		}
	}, [control, inView]);

	return (
		<section className="relative pb-10 overflow-hidden" id="contact">
			<div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-4 px-4 xl:px-0 xl:place-content-center xl:h-[40vh]">
				<motion.div
					animate={control}
					ref={ref}
					variants={{ ...item(1) }}
					initial="hide"
					className="h-full space-y-4">
					<h1 className="font-roboto-slab capitalize font-medium text-xl sm:text-2xl lg:text-4xl text-[#2F303F]">
						contact us
					</h1>

					<p className="font-roboto font-normal text-lg sm:text-xl text-[#2F303F]">
						At {process.env.REACT_APP_AGENT_NAME}, we're dedicated to providing
						top-notch services to keep you connected and entertained. Reach out
						to us through any of the following methods:
					</p>
					<div className="space-y-1">
						<div className="flex items-center space-x-0.5 text-[#2F303F] text-lg">
							<h3 className="capitalize font-roboto font-semibold">address:</h3>
							<p className="font-normal font-roboto">
								{process.env.REACT_APP_AGENT_ADDRESS}
							</p>
						</div>

						<div className="flex items-center space-x-0.5 text-lg text-[#2F303F]">
							<h3 className="capitalize font-roboto font-semibold">
								whatsApp:
							</h3>
							<p className="font-normal font-roboto">
								{process.env.REACT_APP_AGENT_WHATSAPP
									? process.env.REACT_APP_AGENT_WHATSAPP?.replace(
											"https://wa.me/234",
											"0"
									  )
									: process.env.REACT_APP_AGENT_TELEPHONE}
							</p>
						</div>

						<div className="flex items-center space-x-0.5 text-[#2F303F] text-lg">
							<h3 className="capitalize font-roboto font-semibold">call:</h3>
							<p className="font-normal font-roboto">
								{process.env.REACT_APP_AGENT_TELEPHONE}
							</p>
						</div>

						<div className="flex items-center space-x-0.5 text-[#2F303F] text-lg">
							<h3 className="capitalize font-roboto font-semibold">gmail:</h3>
							<p className="font-normal font-roboto">
								{process.env.REACT_APP_AGENT_EMAIL}
							</p>
						</div>
					</div>
				</motion.div>

				<motion.div
					animate={control}
					ref={ref}
					variants={{ ...item(2) }}
					initial="hide"
					className="rounded-xl overflow-hidden lg:h-[20rem] border">
					<img
						src={Man}
						alt=""
						className="w-full h-full object-cover object-top"
					/>
				</motion.div>
			</div>
		</section>
	);
};
