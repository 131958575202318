import {
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
} from "@headlessui/react";
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/solid";
import { Link, NavLink } from "react-router-dom";
import { classnames } from "../../Utils/classnames";
// import Logo from "../../Assets/dean-logo.png";
import { Fragment } from "react";
import { FooterSection } from "../../Components/home-eighteen/FooterSection";
import { HeroSection } from "../../Components/home-eighteen/hero/HeroSection";
import { ServiceSection } from "../../Components/home-eighteen/service/ServiceSection";
import { BenefitSection } from "../../Components/home-eighteen/benefits/BenefitSection";
import { ReviewSection } from "../../Components/home-eighteen/ReviewSection";
import { ContactSection } from "../../Components/home-eighteen/contact/ContactSection";
import { smoothScroll } from "../../Utils";

const routes = [
	{
		title: "about",
		to: "#about",
		current: true,
	},
	{
		title: "contact",
		to: "#contact",
		current: true,
	},
	{
		title: "service",
		to: "#service",
		current: true,
	},
];

export default function HomeEighteen() {
	return (
		<Fragment>
			<Disclosure
				as="nav"
				className="fixed top-0 left-0 z-20 right-0 bg-[#3E3F94]">
				{({ open }) => {
					return (
						<>
							<div className="mx-auto max-w-7xl px-2 sm:px-2 lg:px-4 xl:p-0">
								<div className="relative flex items-center justify-between py-2 h-20">
									<div className="flex flex-1 items-center justify-center sm:justify-start">
										<img
											src={process.env.REACT_APP_IMAGE_URL}
											alt="logo"
											className="h-14"
										/>
									</div>
									<div className="hidden sm:ml-4 lg:block flex-1">
										<div className="flex items-center space-x-10 w-full justify-end">
											{routes.map(item => (
												<NavLink
													key={item.title}
													to={item.to}
													onClick={() => smoothScroll(item.to.split("#")[1])}
													className={() =>
														classnames(
															// isActive ? 'text-white/60' : 'text-white',
															"text-lg font-normal font-roboto flex-shrink-0 capitalize text-white"
														)
													}
													aria-current={item.current ? "page" : undefined}>
													{item.title}
												</NavLink>
											))}

											<Link
												to="/login"
												className="text-white inline-block rounded font-roboto font-medium text-base capitalize px-4 py-2.5 bg-white/30">
												get started
											</Link>
										</div>
									</div>
									<div className="flex items-center lg:hidden">
										<DisclosureButton className="inline-flex items-center justify-center rounded-md p-1.5 text-gray-200 bg-white/30 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black/20">
											<span className="sr-only">Open main menu</span>
											{open ? (
												<XMarkIcon
													className="block h-8 w-8"
													aria-hidden="true"
												/>
											) : (
												<Bars3Icon
													className="block h-8 w-8"
													aria-hidden="true"
												/>
											)}
										</DisclosureButton>
									</div>
								</div>
							</div>
							<DisclosurePanel className="lg:hidden bg-[#3E3F94]">
								<div className="space-y-3 p-4">
									{routes.map(item => (
										<NavLink
											key={item.title}
											to={item.to}
											className={() =>
												classnames(
													// isActive ? 'text-white/60' : 'text-white',
													"text-lg font-normal block rounded-md px-3 py-2 font-roboto flex-shrink-0 capitalize text-white"
												)
											}
											aria-current={item.current ? "page" : undefined}>
											{item.title}
										</NavLink>
									))}
									<Link
										to="#"
										className="text-white inline-block rounded font-roboto font-medium text-base capitalize px-4 py-2.5 bg-white/30">
										get started
									</Link>
								</div>
							</DisclosurePanel>
						</>
					);
				}}
			</Disclosure>

			<HeroSection />

			<ReviewSection />

			<ServiceSection />

			<BenefitSection />

			<ContactSection />

			<FooterSection routes={routes} />
		</Fragment>
	);
}
