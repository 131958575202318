import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { services } from "../../../Data/reviews";
import { item } from "../../../Utils/framer-motion/motion";

export const ServiceSection = () => {
	const control = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			control.start("show");
		} else {
			control.start("hide");
		}
	}, [control, inView]);

	return (
		<section className="py-10 relative" id="service">
			<div className="max-w-7xl mx-auto xl:h-screen grid h-full xl:place-content-center gap-5">
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-8 px-4 xl:px-0">
					<div className="col-span-full sm:col-span-1 space-y-6">
						<h1 className="capitalize font-roboto-slab font-medium text-xl sm:text-2xl lg:text-3xl text-[#2F303F]">
							explore our services
						</h1>

						<p className="font-roboto font-normal text-lg text-[#2F303F]">
							At {process.env.REACT_APP_AGENT_NAME}, we offer a comprehensive
							range of services designed to make your life easier. Discover how
							our solutions can keep you connected, entertained, and empowered.
						</p>

						<Link
							to="/login"
							className="text-white inline-block rounded font-roboto font-medium text-base capitalize px-6 py-2.5 bg-[#3E3F94] hover:bg-[#3E3F94]/70 transition-all focus:ring-1 focus:ring-offset-2 focus:ring-[#3E3F94]/70 active:bg-[#3E3F94]">
							get started
						</Link>
					</div>

					<div className="col-span-full sm:col-span-1">
						<div className="w-full bg-[#D9D9D9] rounded-lg flex flex-col items-center p-10 space-y-10">
							<span className="size-16 rounded-full flex items-center justify-center bg-[#3E3F94] border">
								<svg
									width="32"
									height="32"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M8.11249 14.2583C10.0792 12.2917 13.5208 12.2917 15.4875 14.2583"
										stroke="white"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M18.1917 11.3083C14.4869 8.03056 9.34166 8.03056 5.40833 11.3083"
										stroke="white"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M1.96667 8.35832C8.1772 3.1139 15.4228 3.11389 21.6333 8.35821"
										stroke="white"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M11.8 19.175C12.6146 19.175 13.275 18.5146 13.275 17.7C13.275 16.8854 12.6146 16.225 11.8 16.225C10.9854 16.225 10.325 16.8854 10.325 17.7C10.325 18.5146 10.9854 19.175 11.8 19.175Z"
										stroke="white"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>

							<p className="text-lg text-center font-roboto font-normal text-[#2F303F]">
								Enjoy internet browsing always with our attractive data rates.
								Start your data reselling business with us today and enjoy
								profitable discounts for all networks.
							</p>
						</div>
					</div>
				</div>

				<div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5 px-4 xl:px-0 ">
					{services.map(({ Icon, title, service }, i) => (
						<motion.div
							ref={ref}
							key={title}
							variants={{ ...item(i) }}
							animate={control}
							initial="hide"
							className="w-full bg-[#D9D9D9] rounded-lg flex flex-col items-center p-5 space-y-3">
							<span className="size-16 rounded-full flex items-center justify-center bg-[#3E3F94] border">
								{Icon}
							</span>

							<h3 className="text-lg sm:text-xl font-robot font-medium capitalize">
								{title}
							</h3>

							<p className="text-lg text-center font-roboto font-normal text-[#2F303F] leading-[1.8]">
								{service}
							</p>
						</motion.div>
					))}
				</div>
			</div>
		</section>
	);
};
