import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { socials } from "../../Components/Footer";
// import { Link } from "react-scroll";
import homnineteenhero from "../../Assets/homnineteenhero.png";
import HomeNinteenNav from "../../Components/home-ninteen/HomeNinteenNav";
import aboutimg from "../../Assets/aboutimg.png";
import orderimg from "../../Assets/orderimg.png";
import airtimeimg from "../../Assets/airtimeimg.png";
import dataomg from "../../Assets/dataomg.png";
import educationimg from "../../Assets/educationimg.png";
import tvimg from "../../Assets/tvimg.png";
import testimg from "../../Assets/testimg.png";
import usertest from "../../Assets/usertest.png";
import rate from "../../Assets/rate.png";
import footeromg from "../../Assets/footeromg.png";
import instaimg from "../../Assets/instaimg.png";
import twiterimg from "../../Assets/twiterimg.svg";
import faceimg from "../../Assets/faceimg.svg";

const HomeNinteen = () => {
  const cards = [
		{
			img: airtimeimg,
			title: "Airtime Services",
			text: `${process.env.REACT_APP_AGENT_NAME} offers convenient and rapid airtime top-up services at competitive rates. Our low charges and quick recharge services make it easy for customers to top up their airtime.`,
		},
		{
			img: dataomg,
			title: "Data Services",
			text: `${process.env.REACT_APP_AGENT_NAME} is a reliable and affordable platform for purchasing affordable data bundles and airtime top-ups for any Nigerian network. We offer competitive pricing for data plans from Airtel, MTN, 9mobile, and Glo.`,
		},
		{
			img: educationimg,
			title: "Education",
			text: `${process.env.REACT_APP_AGENT_NAME} is your go-to source for various examination scratch cards, including those for Waec, Neco, and Nabteb. We take pride in providing top-quality service and aim to make our website your go-to destination after your first experience with us.`,
		},
		{
			img: airtimeimg,
			title: "Cable Subscriptions",
			text: `At ${process.env.REACT_APP_AGENT_NAME}, we offer discounted rates for renewing your DSTV, GOTV, and Startimes subscriptions, allowing you to save money on your cable service. Our convenient access to subscription renewal saves you time and effort in obtaining your cable service.`,
		},
		{
			img: tvimg,
			title: "TV Subscriptions",
			text: `At ${process.env.REACT_APP_AGENT_NAME}, we offer discounted rates for renewing your DSTV, GOTV, and Startimes subscriptions, allowing you to save money on your cable service. Our convenient access to subscription renewal saves you time and effort in obtaining your cable service.`,
		},
		{
			img: airtimeimg,
			title: "Electricity Payment",
			text: "Do you want to stay on top of your finances, then make you can make use of  our pocket-friendly electricity payment feature. It saves more time and money with our streamlined payment tools.",
		},
	];
  return (
		<div className=" w-full mx-auto overflow-hidden">
			<div className=" lg:space-y-[-100px] md:space-y-0 space-y-0">
				<div
					className=" bg-cover bg-no-repeat "
					style={{
						backgroundImage: `url(${homnineteenhero})`,
						backgroundSize: "100% 100%",
					}}>
					<div className=" w-full h-full bg-[#0C778E] bg-opacity-75">
						<HomeNinteenNav />

						<div className="grid md:px-16 lg:px-28 px-8 pb-[24px] justify-center mx-auto w-full h-screen">
							<div className=" grid justify-center space-y-[-100px] text-white mt-40">
								<div className="grid justify-center text-center lg:w-[900px]">
									<p className=" lg:text-[70px] font-Russo lg:leading-[90px] font-bold md:text-[52px] text-[42px]">
										Never run out of data. Stay Connected!
									</p>
								</div>
								<div className="grid justify-center text-center ">
									<p className=" max-w-[400px] w-full px-3 pt-16">
										In a few clicks, buy data to keep surfing the internet. You
										can buy whatever size of data plan for whichever network you
										desire. Get Started!
									</p>
								</div>

								<div className="flex justify-center  pt-20">
									<RouterLink
										to="/login"
										className=" bg-white w-[157px] justify-center grid items-center no-underline h-[42px] text-black font-bold rounded-full">
										Get Started
									</RouterLink>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				name="about"
				className="grid md:px-16 lg:px-28 px-8 pb-[24px] lg:mt-14  md:mt35 mt-30 justify-center mx-auto w-full py-10">
				<div className=" lg:flex md:grid grid lg:justify-between md:justify-center gap-10 justify-center items-center">
					<div className=" lg:w-1/2 md:w-full w-full grid">
						<button className=" border border-[#0C778E] rounded-full w-36 h-10 text-lg Inter font-bold">
							ABOUT US
						</button>
						<p className="Sansation font-bold text-5xl  text-[#1B1B1B] w-full lg:max-w-[65%] mt-3">
							Optimise Your Business Fast But Secure
						</p>
						<p className=" text-2xl Sansation text-[#1B1B1B]  font-normal w-full lg:max-w-[60%] ">
							Our user-friendly app & secure online platform make managing your
							finances easy.
						</p>
						<button className=" border bg-[#FDD60B] rounded-full w-36 h-10 text-lg Inter font-bold mt-10">
							Get Started
						</button>
					</div>
					<div className=" lg:w-1/2 md:w-full w-full">
						<img src={aboutimg} alt="" />
					</div>
				</div>
			</div>

			<div
				data-aos="fade-left"
				data-aos-duration="3000"
				className="grid md:px-16 lg:px-28 lg:h-[255px] md:h-[200px] h-[200px] px-8 lg:mt-14 md:mt35 mt-30 mx-auto w-full">
				<div className=" text-black mt-10">
					<p className=" font-sansation lg:text-[20px] md:text-[14px] text-[12px] text-center font-bold">
						Trusted by 4,000+ companies
					</p>
				</div>

				<div className="overflow-hidden">
					<div className="flex animate-marquee items-center">
						<img
							className=" lg:h-[48px] lg:w-[110px] md:h-[30px] md:w-[70px] h-[20px] w-[60px] text-white"
							src={require("../../Assets/Netflix.png")}
							alt=""
						/>
						<img
							className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/Canva.png")}
							alt=""
						/>
						<img
							className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/Adobe.png")}
							alt=""
						/>
						<img
							className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/Grammaly.png")}
							alt=""
						/>
						<img
							className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/Airbnb.png")}
							alt=""
						/>
						<img
							className=" lg:h-[48px] lg:w-[110px] md:h-[38px] md:w-[100px] h-[28px] w-[80px]"
							src={require("../../Assets/Amazon.png")}
							alt=""
						/>
					</div>
				</div>
			</div>

			<div
				className=" bg-cover bg-no-repeat "
				style={{
					backgroundImage: `url(${orderimg})`,
					backgroundSize: "100% 100%",
				}}>
				<div className="w-full h-full bg-[#0C778E] bg-opacity-80 grid justify-center items-center py-40">
					<div className=" w-full max-w-[100%] bg-white bg-opacity-75 p-3">
						<p className=" text-center Sansation lg:text-5xl md:text-4xl text-3xl bg-gradient-to-r from-[#0B6072] via-[#FFFFFF] to-[#FFFFFF] bg-clip-text text-transparent">
							You Will Never Regret Doing Business With Us
						</p>
						<div className=" grid justify-center items-center">
							<p className=" text-center lg:text-2xl md:text-2xl text-xl font-medium Sansation lg:w-[500px] text-[#fff]">
								Enjoy peace of mind with our 24/7 customer support and expert
								guidance.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="md:px-16 lg:px-28 px-8 ">
				<div className="w-full h-full grid justify-center items-center py-20">
					<p className=" text-center Sansation lg:text-5xl md:text-4xl text-3xl text-[#1b1b1b]">
						Optimize Your Business Fast And Secured!
					</p>
					<div className=" grid justify-center items-center">
						<p className=" text-center lg:text-2xl md:text-2xl text-lg font-medium Sansation lg:w-[700px] mt-3 text-[#1b1b1b]">
							We offers the best discounts and special deals on all of our
							services, including data, airtime, and cable subscriptions.
						</p>
					</div>
					<div className=" justify-center grid items-center">
						<button className=" border bg-[#FDD60B] rounded-full w-36 h-10 text-lg Inter font-bold mt-10">
							Get Started
						</button>
					</div>
				</div>
			</div>

			<div className="md:px-16 lg:px-28 px-8 bg-[#0C778E] py-16">
				<div className=" justify-center grid items-center">
					<p className=" text-center Sansation text-5xl text-[#fff] ">
						OUR SERVICES
					</p>
					<div className=" grid justify-center items-center">
						<p className=" text-center lg:text-2xl md:text-2xl text-lg font-medium Sansation lg:w-[700px] mt-2 text-[#fff]">
							We have the BEST Features For Your Subscriptions. You can trust us
							with your subscriptions.
						</p>
					</div>
				</div>
				<div className=" grid justify-center items-center mt-5">
					<div className=" grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 justify-center items-center gap-5">
						{cards.map(i => (
							<div className=" grid">
								<img className=" w-20 h-20" src={i.img} alt="" />
								<p className=" Sansation text-lg font-semibold text-[#fff] pt-2">
									{i.title}
								</p>
								<p className=" text-[#fff]">{i.text}</p>
							</div>
						))}
					</div>
				</div>
			</div>

			<div className="md:px-16 lg:px-32 px-8 py-20">
				<div className=" lg:flex md:grid grid items-center justify-center relative">
					<div className=" lg:w-[70%] md:w-full w-full">
						<img
							className=" w-full lg:h-[400px] md:h-[400px] h-[300px]"
							src={testimg}
							alt=""
						/>
					</div>
					<div className=" lg:w-[30%] md:w-full w-full">
						<div className="  bg-[#F7F5FF] p-5 rounded-2xl lg:absolute lg:right-24 lg:top-24 lg:w-[500px]">
							<div className=" flex items-center justify-between">
								<div className=" flex gap-2 items-center">
									<img className=" w-12 h-12" src={usertest} alt="" />
									<div className=" space-y-[-6px]">
										<p className=" font-Nunito text-base text-[#1b1b1b] font-semibold ">
											Customer Name
										</p>
										<p className=" font-Nunito text-sm text-[#1b1b1b] font-normal">
											Occupation
										</p>
									</div>
								</div>
								<div>
									<img className=" w-28 h-4" src={rate} alt="" />
								</div>
							</div>

							<div>
								<p className=" text-sm font-normal font-Nunito text-[#1b1b1b] pt-5">
									I've tried a lot of financial management tools, but this one
									is by far the best. It's intuitive and user-friendly, even for
									someone like me who isn't great with technology.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<footer
				className=" bg-cover bg-no-repeat "
				style={{
					backgroundImage: `url(${footeromg})`,
					backgroundSize: "100% 100%",
				}}>
				<div className=" w-full h-full bg-[#054654] bg-opacity-75 pt-16">
					<div className=" justify-center grid items-center">
						<p className=" text-center Sansation text-5xl text-[#fff]">
							Subscribe to Our Newsletter
						</p>
					</div>
					<div className=" justify-center grid items-center">
						<p className=" text-xl font-Nunito font-normal text-white">
							To join the worldwide community
						</p>
					</div>

					<div className=" flex justify-center items-center mt-4">
						<div className=" items-center justify-center flex relative">
							<input
								className=" lg:w-[613px] text-black md:w-[420px] w-[230px] lg:h-[67px] md:h-[47px] h-[37px] lg:px-2 rounded-lg"
								type="text"
							/>

							<RouterLink
								to="/login"
								className=" absolute right-2 lg:w-[150px] grid justify-center items-center no-underline md:w-[100px] w-[80px]   lg:h-[57px] md:h-[37px] h-[29px] bg-homeSixCol rounded-lg text-white">
								Join Now
							</RouterLink>
						</div>
					</div>

					<div className=" py-4">
						<div className=" flex items-center justify-center">
							<ul className="flex items-center gap-10">
								<li className=" text-sm Sansation text-white font-normal">
									About
								</li>
								<li className=" text-sm Sansation text-white font-normal">
									Contact
								</li>
								<li className=" text-sm Sansation text-white font-normal">
									Services
								</li>
							</ul>
						</div>
						<div className=" flex items-center justify-center">
							<div className="items-center justify-center gap-5 hidden">
								<img className=" w-6 h-6" src={instaimg} alt="" />
								<img className=" w-6 h-6" src={twiterimg} alt="" />
								<img className=" w-6 h-6" src={faceimg} alt="" />
							</div>
							<ul className="list-group border-0 list-group-horizontal">
								{socials.map((item, index) => (
									<li
										key={index}
										className="list-group-item border-0 bg-transparent text-white d-flex align-items-center">
										<a
											className="text-white text-decoration-none fontReduce"
											target={"_blank"}
											rel="noreferrer"
											href={
												item?.type === "mail" && item?.url
													? `mailto:${item?.url}`
													: item?.url || "#"
											}>
											{item?.icon}
										</a>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className=" lg:px-32">
						<hr className=" text-white" />
						<p className=" text-sm font-Nunito text-white text-center font-medium">
							Copyright © {new Date().getFullYear()}. All rights reserved
						</p>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default HomeNinteen;
