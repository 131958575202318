import HeroImgOne from "../../../Assets/hero-img-1.png";
import HeroImgTwo from "../../../Assets/hero-img-2.png";
import Playstore from "../../../Assets/playstore.png";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

export const HeroSection = () => {
	const control = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			control.start("final");
		} else {
			control.start("initial");
		}
	}, [control, inView]);

	const descriptionVariants = {
		final: {
			x: 0,
			opacity: 1,
			transition: {
				duration: 0.6,
			},
		},
		initial: {
			x: -100,
			opacity: 0,
		},
	};

	const imageVariants = {
		final: {
			x: 0,
			opacity: 1,
			transition: {
				duration: 0.6,
			},
		},

		initial: {
			x: 200,
			opacity: 0,
		},
	};

	return (
		<main className="relative bg-[#3E3F94] overflow-hidden" id="about">
			<motion.div
				variants={{
					transition: {
						startOffset: 0.6,
					},
				}}
				className="max-w-7xl mx-auto h-full lg:h-[80vh] pt-24 lg:pt-0 flex flex-col p-4 xl:p-0 lg:flex-row justify-start items-start lg:items-center">
				<motion.div
					ref={ref}
					variants={descriptionVariants}
					initial="initial"
					animate={control}
					className="space-y-7">
					<h2 className="text-white font-roboto-slab font-medium text-2xl lg:text-3xl xl:text-5xl xl:leading-snug">
						Simplify Your Digital <br className="hidden lg:block" /> Life with{" "}
						{process.env.REACT_APP_AGENT_NAME}
					</h2>
					<p className="font-roboto font-normal text-lg text-white">
						At {process.env.REACT_APP_AGENT_NAME}, we make it easy for you to
						stay connected and entertained. Whether you need to top up your
						airtime, buy data, subscribe to your favorite TV channels, or pay
						your electricity bill, we've got you covered.
					</p>

					<div className="flex flex-col space-y-1.5 sm:space-y-0 sm:flex-row sm:space-x-7 sm:items-center">
						<Link
							to="/login"
							className="text-white focus:ring-offset-2 focus:ring-1 inline-block w-fit rounded font-roboto font-medium text-base capitalize px-10 py-3 bg-white/30">
							get started
						</Link>
						<a
							href={process.env.REACT_APP_GOOGLE_PLAY || "#"}
							className="w-40 rounded transition-all focus:ring-1 focus:ring-offset-2 no-underline">
							<img src={Playstore} alt="playstore" />
						</a>
					</div>
				</motion.div>

				<motion.div
					ref={ref}
					variants={imageVariants}
					initial="initial"
					animate={control}
					className="hidden relative md:flex items-center -bottom-10 lg:-bottom-20 xl:-bottom-16">
					<div className="w-[22rem] lg:w-[20rem] xl:w-[25rem] relative left-28">
						<img
							src={HeroImgTwo}
							alt="hero"
							className="object-cover w-full h-full"
						/>
					</div>
					<div className="w-[22rem] lg:w-[19rem] xl:w-[24rem] relative z-10">
						<img
							src={HeroImgOne}
							alt="hero"
							className="object-cover w-full h-full"
						/>
					</div>
				</motion.div>
			</motion.div>
		</main>
	);
};
