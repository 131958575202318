import { useNavigate } from "react-router-dom";
const Button = () => {
	let navigate = useNavigate();

	return (
		<button
			onClick={() => navigate("/login")}
			className="h-16 px-8 bg-main text-white font-medium uppercase">
			get started
		</button>
	);
};

export default Button;
