import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";

const Benefits = ({ title }) => {
	// eslint-disable-next-line no-unused-vars
	const [show, setShow] = useState(false);

	return (
		<div
			data-aos="zoom-in-up"
			data-aos-duration="2000"
			className="flex justify-between gap-8 bg-white max-w-lg text-dark px-8 py-4 rounded-md mb-4">
			<div className="flex gap-8">
				<span className="text-tetiary">
					<div className="h-8 w-8 bg-secondary flex items-center justify-center rounded">
						<CheckIcon />
					</div>
				</span>
				<article className="">
					<p className="font-medium text-sm md:text-lg">{title}</p>
					<p className={`text-xs md:text-sm mt-4 ${!show && "hidden"}`}>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
						purus sit amet luctus venenatis, lectus magna fringilla urna,
						porttitor
					</p>
				</article>
			</div>
			<span className="cursor-pointer text-tetiary">
				{show ? <CloseIcon /> : <AddIcon />}
			</span>
		</div>
	);
};

export default Benefits;
